import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { N200 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	NEXT_COMMENT_SHORTCUT,
	NEXT_COMMENT_SHORTCUT_IN_EDIT,
	PREV_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { InlineHeaderButton, NavigationContainer } from './styled-components';

const i18n = defineMessages({
	nextCommentLabel: {
		id: 'inline-comments-common.next-comment-label',
		description: 'Label displayed for the Next comment',
		defaultMessage: 'Next comment',
	},
	previousCommentLabel: {
		id: 'inline-comments-common.previous-comment-label',
		description: 'Label displayed for the Previous comment',
		defaultMessage: 'Previous comment',
	},
});

type CommentNavigationProps = {
	currentCommentIndex?: number;
	commentsCount?: number;
	isSSRFocusedComment?: boolean;
	disableCommentCount?: boolean;
	goToNextInlineComment: (arg?: boolean) => void;
	goToPrevInlineComment: (arg?: boolean) => void;
};

export const CommentNavigation: FC<CommentNavigationProps> = ({
	currentCommentIndex,
	commentsCount,
	isSSRFocusedComment,
	goToNextInlineComment,
	goToPrevInlineComment,
	disableCommentCount = false,
}) => {
	const { formatMessage } = useIntl();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const noop = () => {};
	const renderCommentIndexAndCounts = () => {
		if (disableCommentCount || currentCommentIndex === -1) {
			return null;
		}
		/* doing typecheck instead of if(currentCommentIndex && commentsCount) since
    currentCommentIndex can be 0 */
		if (typeof currentCommentIndex === 'number' && typeof commentsCount === 'number') {
			return (
				<span data-testid="comment-index-and-counts">{`${
					currentCommentIndex + 1
				} of ${commentsCount}`}</span>
			);
		} else {
			return null;
		}
	};

	return (
		<NavigationContainer data-testid="comment-navigation-container">
			<Tooltip
				content={
					<ShortcutVisualizer
						contentBefore={<FormattedMessage {...i18n.previousCommentLabel} />}
						shortcut={
							(isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')) ||
							(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
								? PREV_COMMENT_SHORTCUT_IN_EDIT
								: PREV_COMMENT_SHORTCUT
						}
					/>
				}
				hideTooltipOnClick
				position="top"
			>
				<InlineHeaderButton
					isSSRFocusedComment={isSSRFocusedComment}
					data-cy="inline-nav-previous"
					testId="comment-navigation-prev"
					iconBefore={
						<ChevronUpIcon
							label={formatMessage(i18n.previousCommentLabel)}
							color={token('color.icon.subtle', N200)}
							LEGACY_size="medium"
							spacing="spacious"
						/>
					}
					onClick={!isSSRFocusedComment ? () => goToPrevInlineComment() : noop}
				/>
			</Tooltip>
			<Tooltip
				content={
					<ShortcutVisualizer
						contentBefore={<FormattedMessage {...i18n.nextCommentLabel} />}
						shortcut={
							(isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')) ||
							(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
								? NEXT_COMMENT_SHORTCUT_IN_EDIT
								: NEXT_COMMENT_SHORTCUT
						}
					/>
				}
				hideTooltipOnClick
				position="top"
			>
				<InlineHeaderButton
					isSSRFocusedComment={isSSRFocusedComment}
					data-cy="inline-nav-next"
					testId="comment-navigation-next"
					iconBefore={
						<ChevronDownIcon
							label={formatMessage(i18n.nextCommentLabel)}
							color={token('color.icon.subtle', N200)}
							LEGACY_size="medium"
							spacing="spacious"
						/>
					}
					onClick={!isSSRFocusedComment ? () => goToNextInlineComment() : noop}
				/>
			</Tooltip>
			{(isLiveEditMode || isOnEditRoute) && (
				<>
					<GeneralShortcutListener
						accelerator={NEXT_COMMENT_SHORTCUT_IN_EDIT}
						listener={() => goToNextInlineComment(true)}
					/>
					<GeneralShortcutListener
						accelerator={PREV_COMMENT_SHORTCUT_IN_EDIT}
						listener={() => goToPrevInlineComment(true)}
					/>
				</>
			)}
			{!(isLiveEditMode || isOnEditRoute) &&
				!fg('confluence_inline_comments_keyboard_nav_renderer') && (
					<>
						<GeneralShortcutListener
							accelerator={NEXT_COMMENT_SHORTCUT}
							listener={() => goToNextInlineComment(true)}
						/>
						<GeneralShortcutListener
							accelerator={PREV_COMMENT_SHORTCUT}
							listener={() => goToPrevInlineComment(true)}
						/>
					</>
				)}
			{process.env.REACT_SSR ? <span id="inline-comment-index" /> : renderCommentIndexAndCounts()}
		</NavigationContainer>
	);
};
