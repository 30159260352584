import { useMemo, useCallback } from 'react';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useInlineCommentsDispatchContext } from '@confluence/comment-context';
import { useInlineCommentsState } from '@confluence/inline-comments-hooks';
import { useInlineCommentQueryParams } from '@confluence/comment';

type UseCommentNavigationProps = {
	pageId: string;
	isEditor?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
	commentData: Comment;
};

type useInlineCommentNavigationType = {
	currentCommentIndex: number;
	goToNextInlineComment: () => void;
	goToPrevInlineComment: () => void;
	commentsCount: number;
};

export const useInlineCommentNavigation = ({
	pageId,
	isEditor,
	onNavigationClick,
	commentData,
}: UseCommentNavigationProps): useInlineCommentNavigationType => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { unresolvedInlineComments } = useInlineCommentsState();
	const { removeCommentQueryParams } = useInlineCommentQueryParams();
	const { navigateToComment } = useInlineCommentsDispatchContext();

	const getCommentIndex = useCallback(
		(currentMarkerRef: string): number => {
			return unresolvedInlineComments?.findIndex((markerRef) => markerRef === currentMarkerRef);
		},
		[unresolvedInlineComments],
	);

	const currentInlineMarkerRef = commentData?.location?.inlineMarkerRef ?? '';
	const currentCommentIndex = getCommentIndex(currentInlineMarkerRef);

	const goToNextInlineComment = useCallback(
		(triggeredByKeyboardShortcut = false) => {
			navigateToComment({
				action: 'next',
				triggeredByKeyboardShortcut,
				currentCommentIndex,
				onNavigationClick,
				isEditor,
				removeCommentQueryParams,
				unresolvedInlineComments,
				createAnalyticsEvent,
				contentId: pageId,
			});
		},
		[
			navigateToComment,
			createAnalyticsEvent,
			currentCommentIndex,
			onNavigationClick,
			pageId,
			removeCommentQueryParams,
			unresolvedInlineComments,
			isEditor,
		],
	);

	const goToPrevInlineComment = useCallback(
		(triggeredByKeyboardShortcut = false) => {
			navigateToComment({
				action: 'previous',
				triggeredByKeyboardShortcut,
				currentCommentIndex,
				onNavigationClick,
				isEditor,
				removeCommentQueryParams,
				unresolvedInlineComments,
				createAnalyticsEvent,
				contentId: pageId,
			});
		},
		[
			navigateToComment,
			createAnalyticsEvent,
			currentCommentIndex,
			onNavigationClick,
			pageId,
			removeCommentQueryParams,
			unresolvedInlineComments,
			isEditor,
		],
	);

	return useMemo(
		() => ({
			currentCommentIndex,
			goToNextInlineComment,
			goToPrevInlineComment,
			commentsCount: unresolvedInlineComments.length,
		}),
		[
			goToNextInlineComment,
			goToPrevInlineComment,
			unresolvedInlineComments.length,
			currentCommentIndex,
		],
	);
};
